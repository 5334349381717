import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import { useSpring, animated } from 'react-spring';
import nekotrumpLogoPng from './images/CatTrump.png';
import nekotrumpImage from './images/TRUMPCAT.png';
import tokenomicsImage from './images/TRUMPCAT.png';
import twitterImage from './images/twitter.png';
import telegramImage from './images/telegramicon.png';
import dexscreenerImage from './images/dexscreener.png';
import dextoolsImage from './images/dextools.png';
import nekotrumpAlonePng from './images/CatTrump.png';

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    {/* Additional routes can be defined here */}
                </Routes>
                <footer className="App-footer">
                    <div className="footer-content">
                        <p>&copy; NEKOTRUMP 2024. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        </Router>
    );
}

function Header() {
    return (
        <header className="App-header">
            <div className="header-content">
                <div className="logo">
                    <img src={nekotrumpLogoPng} alt="Nekotrump Logo" />
                </div>
                <h1>nekotrump</h1>
            </div>
        </header>
    );
}

function HomePage() {
    return (
        <main>
            <AnimatedElement>
                <div className="title-container">
                    <h2 className="animated-title">NEKOTRUMP</h2>
                </div>
            </AnimatedElement>
            <AnimatedElement>
                <div className="image-container">
                    <img src={nekotrumpImage} alt="nekotrump" className="reveal-image reveal" />
                </div>
            </AnimatedElement>
            <AnimatedElement>
                {/* New container for the text placed above the buy button */}
                <div className="cat-in-a-pan-container">
                    <p className="just-a-cat-in-a-pan">meow cats great again</p>
                </div>
            </AnimatedElement>
            <AnimatedElement>
                <p className="copy-text">CA: Coming soon!</p>
            </AnimatedElement>

            <AnimatedElement>
                <div className="buy-button">
                    <a href="https://degen.fund" target="_blank" rel="noopener noreferrer">BUY NOW</a>
                </div>
            </AnimatedElement>
            <SocialMediaLinks />
            <CustomSection />
            <TokenomicsSection />
            <ResourcesSection />
        </main>
    );
}

function SocialMediaLinks() {
    return (
        <div className="social-icons">
            <AnimatedElement>
                <a href="https://twitter.com/nekotrumpsol" target="_blank" rel="noopener noreferrer" className="social-icon twitter">
                    <img src={twitterImage} alt="Twitter Icon" />
                </a>
            </AnimatedElement>
            <AnimatedElement>
                <a href="https://dexscreener.com/" target="_blank" rel="noopener noreferrer" className="social-icon dexscreener">
                    <img src={dexscreenerImage} alt="Dexscreener Icon" />
                </a>
            </AnimatedElement>
            <AnimatedElement>
                <a href="https://degen.fund" target="_blank" rel="noopener noreferrer" className="social-icon telegram">
                    <img src={telegramImage} alt="Telegram Icon" />
                </a>
            </AnimatedElement>
            <AnimatedElement>
                <a href="https://www.dextools.io" target="_blank" rel="noopener noreferrer" className="social-icon dextools">
                    <img src={dextoolsImage} alt="Dextools Icon" />
                </a>
            </AnimatedElement>
        </div>
    );
}

function AnimatedElement({ children }) {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            {
                threshold: 0.2,
            }
        );

        observer.observe(ref.current);

        // Store the value of ref.current in a variable
        const currentRef = ref.current;

        return () => {
            observer.unobserve(currentRef); // Use the variable in the cleanup function
        };
    }, []);

    const springProps = useSpring({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0px)' : 'translateY(20px)',
    });

    return <animated.div ref={ref} style={springProps}>{children}</animated.div>;
}

function CustomSection() {
    const scrollToResources = () => {
        const resourcesSection = document.getElementById('resources-section');
        if (resourcesSection) {
            resourcesSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <AnimatedElement>
            <section className="custom-section">
                <div className="gallery">
                    <GalleryItem
                        image={nekotrumpImage}
                        alt="Nekotrump"
                    />
                    <GalleryItem
                        image={nekotrumpImage}
                        alt="Nekotrump"
                    />
                    <GalleryItem
                        image={nekotrumpImage}
                        alt="Nekotrump"
                    />
                    <GalleryItem
                        image={nekotrumpImage}
                        alt="Nekotrump"
                    />
                    <GalleryItem
                        image={nekotrumpImage}
                        alt="Nekotrump"
                    />
                </div>
                <button className="navigate-button" onClick={scrollToResources}>Resources</button>
            </section>
        </AnimatedElement>
    );
}

function GalleryItem({ image, alt }) {
    return (
        <div className="gallery-item">
            <img src={image} alt={alt} className="gallery-image" />
        </div>
    );
}

function TokenomicsSection() {
    return (
        <AnimatedElement>
            <section className="tokenomics">
                <h2>Tokenomics</h2>
                <div className="tokenomics-details">
                    <TokenomicsItem title="Total Supply" value="1,000,000,000" image={tokenomicsImage} />
                    <TokenomicsItem title="LP Burned" value="100%" image={tokenomicsImage} />
                    <TokenomicsItem title="Tax" value="0%" image={tokenomicsImage} />
                </div>
            </section>
        </AnimatedElement>
    );
}

function TokenomicsItem({ title, value, image }) {
    return (
        <div className="tokenomics-item">
            <img src={image} alt={title} className="tokenomics-image" />
            <h3>{title}</h3>
            <p>{value}</p>
        </div>
    );
}

function ResourcesSection() {
    return (
        <AnimatedElement>
            <section id="resources-section" className="resources-section">
                <h2>Resources</h2>
                <div className="resource-items">
                    <ResourceItem name="Full Image" image={nekotrumpImage} />
                    <ResourceItem name="Transparent NEKOTRUMP" image={nekotrumpAlonePng} />
                    {/* Add more resources here */}
                </div>
            </section>
        </AnimatedElement>
    );
}

function ResourceItem({ name, image }) {
    function downloadImage(imagePath) {
        const link = document.createElement('a');
        link.href = imagePath;
        link.download = imagePath.split('/').pop(); // Ensuring the filename is parsed correctly
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className="resource-item">
            <p className="resource-name">{name}</p>
            <div className="resource-preview">
                <img
                    src={image}
                    alt={`${name} preview`}
                    className="resource-image"
                />
            </div>
            <div className="resource-details">
                <button
                    className="download-button"
                    onClick={() => downloadImage(image)}
                >
                    Download
                </button>
            </div>
        </div>
    );
}

export default App;
